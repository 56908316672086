import { authUtils, contactUtils } from 'utils';

import { Contact } from 'types';

export type AllowedColumn =
  | 'name'
  | 'email'
  | 'company'
  | 'partner company'
  | 'department'
  | 'role'
  | 'approval'
  | 'topologies';

export const DefaultColumns: AllowedColumn[] = [
  'name',
  'email',
  'company',
  'partner company',
  'department',
  'role',
  'approval',
  'topologies'
];

export function DisplayedColumns(
  columns: AllowedColumn[],
  contacts: Contact[]
): AllowedColumn[] {
  const hasDepartment = (c: Contact): boolean => c.department !== '';
  const hasPartnerCompany = (c: Contact): boolean => c.partnerCompany !== '';
  if (!contactUtils.isAdminOrSupport(authUtils.getLoggedInUser())) {
    columns = columns.filter(col => col !== 'email');
  }
  return columns.filter(
    col =>
      (col === 'department' && contacts.some(hasDepartment)) ||
      (col === 'partner company' && contacts.some(hasPartnerCompany)) ||
      (col !== 'department' && col !== 'partner company')
  );
}
