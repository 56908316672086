import { isEmpty, isEqual } from 'lodash';
import React, { memo, useEffect, useState } from 'react';
import { Button, Dropdown, Icon, Message, Modal } from 'semantic-ui-react';

import { notifier, tagOptions, tagUtils } from 'utils';
import { reloadData } from 'utils/urls';

import { tagsApi } from 'api/tag';
import { Nbsp } from 'components/Nbsp/Nbsp';
import { Topology } from 'types';
import { Tag } from 'types/Tags/tags';

import './styles.less';

type Props = {
  readonly open: boolean;
  readonly onClose: (value: boolean) => void;
  readonly topology: Topology;
};

export const TagSelectionModal: React.FC<Props> = memo(
  ({ open, onClose, topology }) => {
    const [localTopology, setLocalTopology] = useState<Topology>();
    const [existingTags, setExistingTags] = useState<Tag[]>([]);
    const [allTags, setAllTags] = useState<Tag[]>([]);
    const [localTag, setTags] = useState<string[]>([]);
    const [tagLoading, setLoading] = useState<boolean>(false);

    useEffect(() => {
      const getTags = async () => {
        try {
          setLoading(true);
          const response = await tagsApi.getAll();
          setAllTags(response.tags);
          const filterResponse = response.tags.filter(tag =>
            tagUtils.nonDepartmentTag(tag)
          );
          setExistingTags(filterResponse);
          const topologyTags = topology.tags.filter(tag =>
            tagUtils.nonDepartmentTag(tag)
          );
          const tagsMatch = topologyTags.map(tag => {
            filterResponse.forEach(
              tTags => tTags.name === tag.name && tTags.value === tag.value
            );
            return `${tag.name}:${tag.value}`;
          });
          setTags(tagsMatch);
          setLoading(false);
        } catch (err) {
          notifier.requestFailed(err);
        }
      };
      if (open && !isEqual(topology, localTopology)) {
        setLocalTopology(topology);
        getTags();
      }
    }, [topology, open, localTopology]);

    const availableOptions: tagOptions[] = existingTags
      ? existingTags.map(option => {
          return {
            key: option.id,
            value: `${option.name}:${option.value}`,
            text: `${option.name}:${option.value}`
          };
        })
      : [];
    const placeholderText = isEmpty(availableOptions)
      ? 'No tags available'
      : 'Select Tags';

    const includesTag = (original: Partial<Tag>, selected: Partial<Tag>) => {
      return (
        selected.name === original.name && selected.value === original.value
      );
    };
    const getIdForSelectedTags = (): Tag[] => {
      const selectedTags =
        localTag.length >= 1
          ? localTag.map(tag => {
              const selected = tag.split(':');
              return {
                name: selected[0],
                value: selected[1]
              };
            })
          : [];

      const filterTags: Partial<Tag>[] = [];
      selectedTags.forEach(tag => filterTags.push(tag));
      const departmentTag =
        topology &&
        topology.tags.filter(tag => {
          return !tagUtils.nonDepartmentTag(tag);
        });
      filterTags.push(...departmentTag);
      return allTags.filter(tag => {
        return filterTags.find(selected => includesTag(tag, selected));
      });
    };
    const modifyTags = async () => {
      const newTags = getIdForSelectedTags();
      try {
        await tagsApi.updateTopologyTag(topology.uuid, { tags: newTags });
        notifier.success({
          message: `Topology "${topology.name}" updated with Tags`
        });
        reloadData();
      } catch (err) {
        notifier.requestFailed(err);
      }
      onClose(false);
    };

    return (
      <Modal
        open={open}
        closeIcon={true}
        closeOnDimmerClick={false}
        size='tiny'
        onClose={() => {
          onClose(false);
        }}
      >
        <Modal.Header>Manage tags</Modal.Header>
        <Modal.Content>
          <Message
            info
            content={
              <span className='message-wrap'>
                Please select a value to add or remove a tag to the selected
                Topology "{topology.name}" <Nbsp />
              </span>
            }
          />
          <div className='modal-content'>
            <Modal.Description>
              <div>
                <Dropdown
                  placeholder={placeholderText}
                  data-testid='tags-selection'
                  fluid
                  selection
                  multiple
                  search
                  loading={tagLoading}
                  value={localTag}
                  options={availableOptions}
                  onChange={(event, data) => {
                    setTags(data.value as string[]);
                  }}
                />
              </div>
            </Modal.Description>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <div className='flex-1'>
            <Button
              className=''
              color='red'
              inverted
              onClick={() => onClose(false)}
            >
              <Icon name='remove' /> Cancel
            </Button>
            <Button color='green' inverted onClick={() => modifyTags()}>
              <Icon name='checkmark' /> Apply
            </Button>
          </div>
        </Modal.Actions>
      </Modal>
    );
  }
);
