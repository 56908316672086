import React, { memo, useState } from 'react';
import { Button, Icon, Label, Loader } from 'semantic-ui-react';
import { notifier, tagUtils } from 'utils';

import { tagsApi } from 'api/tag';
import { Tag } from 'types/tag';
import { TopologyDetails } from 'types/topology/topology-details';

import './styles.less';

type Props = {
  readonly onOpen: (value: boolean) => void;
  readonly topology: TopologyDetails;
  readonly refresh: () => void;
};

export const TopologyTags: React.FC<Props> = memo(
  ({ topology, onOpen, refresh }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const getModifiedTags = (selectedTag: Tag): Tag[] => {
      return topology.tags.filter(
        (tag: Tag) =>
          tag.name !== selectedTag.name && tag.value !== selectedTag.value
      );
    };
    const removeTagFilter = async (selectedTag: Tag) => {
      const newTags = getModifiedTags(selectedTag);
      try {
        setLoading(true);
        await tagsApi.updateTopologyTag(topology.uuid, { tags: newTags });
        notifier.success({
          message: `Topology "${topology.name}" updated with Tags`
        });
        refresh();
      } catch (err) {
        notifier.requestFailed(err);
      }
    };
    return (
      <div className='topology-tags'>
        {topology.tags.length > 0 ? (
          topology.tags.map(tag => (
            <Label key={tag.name}>
              {tag.name}:{tag.value}
              {tagUtils.nonDepartmentTag(tag) && (
                <Icon
                  data-testid='delete-icon'
                  name='delete'
                  onClick={() => removeTagFilter(tag)}
                />
              )}
            </Label>
          ))
        ) : (
          <Label content='No tags added' />
        )}
        <Button basic size='tiny' onClick={() => onOpen(true)}>
          <Icon name='plus' />
          Add Tags
        </Button>
        {loading && <Loader size='mini' />}
      </div>
    );
  }
);
