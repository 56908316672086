import React, { memo, useCallback } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Button, Card, Icon, Popup } from 'semantic-ui-react';

import { notifier } from 'utils';

import { RouterLink } from 'components';
import { VMAccess } from 'types';

type Props = {
  readonly bastionFqdn: string;
  readonly bastionSsh: VMAccess;
};

export const BastionUsageMessage: React.FC<Props> = memo(
  ({ bastionFqdn, bastionSsh }) => {
    const { username, password, host, port } = bastionSsh;
    const onCopy = useCallback((stringType: string) => {
      notifier.success(
        { message: `${stringType} Copied` },
        { autoClose: 2000 }
      );
    }, []);
    const sshCommand = `ssh ${username}@${host} -p ${port}`;
    return (
      <Card>
        <Card.Content>
          <Card.Header>SSH access to VMs</Card.Header>
          <Card.Content>
            <p>
              All devices in this topology are accessible using the "Connect"
              button below.
            </p>
            <p>
              When connected, input the number of the device you want to connect
              to from the menu. Login credentials are listed in the table.
            </p>
          </Card.Content>
        </Card.Content>
        <Card.Content>
          <RouterLink to={bastionFqdn}>
            <Button primary>Connect</Button>
          </RouterLink>
        </Card.Content>
        <Card.Content>
          <b> SSH command: </b>
          <br />
          <Popup
            content='Copy SSH command to Clipboard'
            position='top center'
            trigger={
              <CopyToClipboard
                text={sshCommand}
                onCopy={() => onCopy('SSH command')}
              >
                <Icon name='clipboard' link />
              </CopyToClipboard>
            }
          />
          {sshCommand}
        </Card.Content>
        <Card.Content>
          <b> SSH password: </b>
          <br />
          <Popup
            content='Copy SSH password to Clipboard'
            position='top center'
            trigger={
              <CopyToClipboard
                text={password}
                onCopy={() => onCopy('SSH password')}
              >
                <Icon name='clipboard' link />
              </CopyToClipboard>
            }
          />
          {password}
        </Card.Content>
      </Card>
    );
  }
);
