import { isEmpty } from 'lodash';
import React, { memo } from 'react';
import { Card, Divider, Icon, Image, Segment } from 'semantic-ui-react';

import { CornerLabel } from 'components/CornerLabel';
import { assets } from 'constants/assets';
import {
  ImageAssets,
  LabTemplate,
  StrictUnion,
  Template,
  TemplateImage
} from 'types';

type TemplateProps = StrictUnion<Template | LabTemplate>;
type Props = {
  readonly template: TemplateProps;
  readonly imageAsURL: string;
  readonly manageTemplatesPreview?: boolean;
  readonly showTemplateImage?: boolean;
  readonly showDeployedLabel?: boolean;
  readonly showLongDescription?: boolean;
  readonly showShortDescription?: boolean;
  readonly showDescription?: boolean;
  readonly showAosVersion?: boolean;
  readonly displayAnimation?: 'slide' | 'dimmer';
};

export const TemplateCard: React.FC<Props> = memo(
  ({
    manageTemplatesPreview = false,
    imageAsURL,
    template,
    showDeployedLabel = false,
    showTemplateImage = false,
    showShortDescription = false,
    showLongDescription = false,
    showDescription = false,
    displayAnimation = 'dimmer',
    showAosVersion = false
  }) => {
    const isTopologyTemplate = template.hasOwnProperty('aosVersion');
    const getImage = (templateImage: TemplateImage[]): ImageAssets =>
      isEmpty(templateImage)
        ? assets.images.noImageAvailable
        : {
            src: templateImage[0].url,
            alt: templateImage[0].name
          };
    const getTemplatePreviewText = () => {
      if (template.label) {
        return !isTopologyTemplate ? (
          <span>
            <Icon name='lab' color='teal' />
            {template.label}
          </span>
        ) : (
          template.label
        );
      } else if (isTopologyTemplate && template.name) {
        return template.name;
      } else if (manageTemplatesPreview) {
        return <i>Template Name required</i>;
      } else {
        return '';
      }
    };
    const truncateLongDescriptionText = (description: string, num: number) => {
      if (description.length <= num) {
        return description;
      }
      return description.slice(0, num) + '...';
    };
    const getUsageText = () => {
      if (template.templateUsage && template.templateUsage.totalCount > 1) {
        return `Used ${template.templateUsage.totalCount} times`;
      }
      return `Used ${
        template.templateUsage ? template.templateUsage.totalCount : 0
      } time`;
    };
    return (
      <div
        className={[
          'container',
          displayAnimation === 'slide' && 'ui slide masked reveal'
        ]
          .filter(Boolean)
          .join(' ')}
      >
        <Segment className='visible content' basic>
          {showDeployedLabel && (
            <CornerLabel
              labelContent={template.deployed ? template.deployed : ''}
              className={template.deployed ? 'deployed-label' : ''}
            />
          )}
          {showTemplateImage && manageTemplatesPreview && (
            <Image src={imageAsURL} />
          )}
          {showTemplateImage && !manageTemplatesPreview && (
            <img
              src={
                getImage(template.templateImages ? template.templateImages : [])
                  .src
              }
              alt={
                getImage(template.templateImages ? template.templateImages : [])
                  .alt
              }
            />
          )}
          {showTemplateImage && <Divider />}
          <Card.Header className='card-common-styles card-header'>
            {getTemplatePreviewText()}
          </Card.Header>
          {showAosVersion && (
            <Card.Meta className='card-common-styles card-description-meta'>
              <b>Apstra Version: </b>
              {template.aosVersion ? (
                template.aosVersion
              ) : manageTemplatesPreview ? (
                <i>Apstra Version required</i>
              ) : (
                ''
              )}
            </Card.Meta>
          )}
          {showShortDescription && (
            <Card.Meta className='card-common-styles card-description'>
              {template.shortDescription ? template.shortDescription : ''}
            </Card.Meta>
          )}
          {showDescription && (
            <Card.Description className='card-common-styles card-description-meta'>
              {template.description ? (
                template.description
              ) : manageTemplatesPreview ? (
                <i>Template Description required</i>
              ) : (
                ''
              )}
            </Card.Description>
          )}
          {showLongDescription && (
            <Card.Description className='card-common-styles long-description'>
              {template.longDescription
                ? truncateLongDescriptionText(
                    template.longDescription,
                    Math.max(225, template.longDescription.length / 2)
                  )
                : ''}
            </Card.Description>
          )}
        </Segment>
        {showLongDescription && (
          <Segment className='hidden content' basic>
            {showLongDescription && (
              <Card.Description className='card-common-styles long-description'>
                {template.longDescription ? template.longDescription : ''}
              </Card.Description>
            )}
          </Segment>
        )}
        <CornerLabel labelContent={getUsageText()} className='created-label' />
      </div>
    );
  }
);
