import React, { memo } from 'react';
import { Label } from 'semantic-ui-react';

import './styles.less';

type Props = {
  readonly labelContent: string;
  readonly className: string;
};

export const CornerLabel: React.FC<Props> = memo(
  ({ labelContent, className }) => {
    return (
      <Label
        className={`blueprint-property ${className}`}
        content={labelContent}
      />
    );
  }
);
